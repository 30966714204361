import { DropdownModel } from '../geral/DropdownModel';
export class FormulaTabelaModel {
  constructor({
    id,
    simbolo,
    descricao = '-',
    unidadeMedida,
    formulaCorrecao
  }) {
    this.id = id;
    this.simbolo = simbolo;
    this.descricao = descricao;
    if (unidadeMedida) {
      if (unidadeMedida?.value){
        this.unidadeMedidaId = unidadeMedida
      }
      else
        this.unidadeMedidaId = new DropdownModel(unidadeMedida);
    }
    this.formulaCorrecaoId = formulaCorrecao;
    if (formulaCorrecao)
      this.formulaCorrecaoId = new DropdownModel(formulaCorrecao);
  }
}
