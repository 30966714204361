<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.codigo"
        class="col-12 col-md-2"
        :label="$t('modulos.formula.formulario.codigo')"
        :readonly="true"
        disabled
      />
      <input-text
        v-model="form.nome"
        class="col-12 col-md-5"
        :label="$t('modulos.formula.formulario.nome')"
        :min="1"
        :max="500"
        obrigatorio
        trim
        :disabled="form.flagSistema || visualizar"
      />
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-5"
        :label="$t('modulos.formula.formulario.descricao')"
        :min="0"
        :max="1000"
        :disabled="form.flagSistema || visualizar"
      />
      <input-text
        v-model="form.simbolo"
        class="col-12 col-md-2"
        :label="$t('modulos.formula.formulario.simbolo')"
        obrigatorio
        :min="1"
        :max="15"
        :disabled="form.flagSistema || visualizar"
      />
      <input-radio
        v-model="form.mensurando"
        class="col-12 col-md-2"
        :divider="false"
        :options="opcoes.booleano"
        :label="$t('modulos.formula.formulario.mensurando')"
        :disabled="form.flagSistema || visualizar"
      />

      <input-formula-mensurando
        ref="inputFormulaMensurando"
        v-model="form.formulaPaiId"
        class="col-12 col-md-5"
        :label="$t('modulos.formula.formulario.formulaMensurando')"
        :multiplos="false"
        :obrigatorio="formulaMensurandoEhObrigatorio"
        :disabled="
          !formulaMensurandoEhObrigatorio || form.flagSistema || visualizar
        "
        :error="false"
        @input="buscarFormulaPai"
      />

      <input-unidade-medida
        v-model="form.unidadeMedidaId"
        class="col-12 col-md-3"
        :label="$t('modulos.formula.formulario.unidadeMedida')"
        :multiplos="false"
        obrigatorio
        :disabled="form.flagSistema || visualizar"
      />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="col-12"
            v-bind="attrs"
            v-on="!form.id && form.mensurando ? on : null"
          >
            <input-ide
              ref="formula"
              v-model="form.formula"
              :label="$t('modulos.formula.formulario.formula')"
              :formula-mensurando-id="
                form.mensurando
                  ? form.id
                  : form.formulaPaiId && form.formulaPaiId.value
              "
              :obrigatorio="!!form.id || !form.mensurando"
              :disabled="
                (!form.id && form.mensurando) || form.flagSistema || visualizar
              "
            />
          </div>
        </template>
        <span>
          {{ $t('modulos.formula.tooltip_formula') }}
        </span>
      </v-tooltip>

      <input-radio
        v-model="form.flagRequerComponentesIncerteza"
        :class="{
          'col-12 col-md-3': form.flagSistema,
          'col-12 col-md-4': !form.flagSistema,
        }"
        :divider="false"
        :options="opcoes.booleano"
        :label="flagRequerEhObrigatorio"
        :disabled="
          !formulaMensurandoEhObrigatorio || form.flagSistema || visualizar
        "
      />

      <input-radio
        v-model="form.flagDisponivelEmCriteriosAceitacao"
        :class="{
          'col-12 col-md-3': form.flagSistema,
          'col-12 col-md-4': !form.flagSistema,
        }"
        :divider="false"
        :options="opcoes.booleano"
        :label="$t('modulos.formula.formulario.disponivel_criterio_aceitacao')"
        :disabled="form.flagSistema || visualizar"
      />

      <input-text
        v-if="form.flagSistema"
        v-model="flagSistema"
        class="col-12 col-md-3"
        :label="$t('modulos.formula.formulario.criada_sistema')"
        disabled
      />

      <input-file
        :value="form.fileImagem"
        accept="image/png"
        visualizador
        :class="{
          'col-12 col-md-3': form.flagSistema,
          'col-12 col-md-4': !form.flagSistema,
        }"
        returns="base64"
        :placeholder="$t('geral.inputs.selecione')"
        :label="$t('modulos.formula.formulario.imagem')"
        :regras-personalizadas="[regraArquivo]"
        :disabled="form.flagSistema || visualizar"
        @clear="desvincularImagem"
        @nome-imagem="nomeImagem"
        @handle-upload="handlerUpload"
      />

      <accordion-padrao
        :titulo="$t('modulos.formula.formulario.variaveis.titulo')"
        :nao-retrair="true"
        class="col-12"
      >
        <tabela-variaveis
          :form="tabela"
          :formula-pai-id="id"
          :mensurando="form.mensurando"
          :unidade-medida-pai="form.unidadeMedidaId"
          :desabilitar-tabela="
            !form.mensurando || form.flagSistema || visualizar
          "
          @tabelaFoiValidada="tabelaFoiValidada"
          @atualizarVariaveisInput="atualizarVariaveisInput"
        />
      </accordion-padrao>
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="form.flagSistema || visualizar"
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>

      <template v-else>
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao
          v-if="!form.flagSistema && !form.id"
          class="mr-2"
          @click="confirmacaoSalvar('sair')"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
        <botao-padrao
          v-if="!form.flagSistema && form.id"
          class="mr-2"
          @click="confirmacaoSalvar('sair')"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvarSair') }}
        </botao-padrao>
        <botao-padrao
          v-if="!form.flagSistema && form.id"
          @click="confirmacaoSalvar('continuar')"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvarContinuar') }}
        </botao-padrao>
      </template>
    </div>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import { InputUnidadeMedida, InputFormulaMensurando } from '@components/inputs';
import InputFile from '@components/inputs/InputFile.vue';
import FormulaService from '@common/services/cadastros/FormulaService.js';
import { FormulaModel } from '@common/models/cadastros/FormulaModel.js';
//import { FormulaFilhaModel } from '@common/models/cadastros/FormulaFilhaModel.js';
import { FormulaTabelaModel } from '@common/models/cadastros/FormulaTabelaModel.js';
import TabelaVariaveis from './components/TabelaVariaveis.vue';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export default {
  components: {
    InputUnidadeMedida,
    InputFormulaMensurando,
    TabelaVariaveis,
    InputFile,
  },
  props: {
    id: { type: String, default: null },
    idFormulaFilha: { type: String, default: null },
  },
  data() {
    return {
      visualizar: false,
      flagSistema: 'Sim',
      tabelaValida: false,
      formulaValida: false,
      valido: false,
      form: new FormulaModel({}),
      opcoes: {
        booleano: helpers.BoleanoEnum,
      },
      error: {
        formulaMensurando: {
          status: false,
          message: '',
        },
      },
      dados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'simbolo',
            text: this.$t(
              'modulos.formula.formulario.variaveis.tabela.simbolo'
            ),
            sortable: false,
            width: '120px',
          },
          {
            value: 'unidadeMedidaId',
            text: this.$t(
              'modulos.formula.formulario.variaveis.tabela.unidadeMedida'
            ),
            width: '230px',
            sortable: false,
          },
          {
            value: 'Formula',
            text: this.$t(
              'modulos.formula.formulario.variaveis.tabela.formulaCorrecao'
            ),
            width: '230px',
            sortable: false,
          },
          {
            value: 'descricao',
            text: this.$t(
              'modulos.formula.formulario.variaveis.tabela.descricao'
            ),
            sortable: false,
          },
        ],
      },
    };
  },
  computed: {
    tituloFormulario() {
      if (this.visualizar) return this.$t('modulos.formula.titulos.visualizar');
      if (this.id) return this.$t('modulos.formula.titulos.editar');
      return this.$t('modulos.formula.titulos.novo');
    },
    tooltipBotaoSalvar() {
      if (this.valido) return '';
      return this.$t('modulos.formula.validacoes.formulario_invalido');
    },
    formulaMensurandoEhObrigatorio() {
      return !this.form.mensurando;
    },
    flagRequerEhObrigatorio() {
      if (this.form.mensurando) {
        return this.$t('modulos.formula.formulario.componentes_incerteza');
      }
      return this.$t(
        'modulos.formula.formulario.componentes_incerteza_obrigatorio'
      );
    },
  },
  watch: {
    id() {
      if (this.id) this.buscar(this.id);
      this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    },
    'form.formula'() {
      this.formulaValida = false;
    },
    'tabela.dados'() {
      this.form.variaveis = this.tabela.dados;
    },
    'form.mensurando'(mensurando) {
      this.$refs.form.resetValidation();
      this.form.flagRequerComponentesIncerteza = false;
      if (!mensurando) {
        this.tabela.dados = [];
        this.setErrors();
        return;
      }

      delete this.form.formulaPaiId;
      this.resetErrors();
    },
    async 'form.formulaPaiId'() {
      if (
        typeof this.form.unidadeMedidaId === 'undefined' ||
        !this.form.unidadeMedidaId
      ) {
        await this.selecionarUnidadeMedida();
        this.$forceUpdate();
      }
    },
  },
  mounted() {
    if (this.$route.name == 'formula-visualizar') {
      this.visualizar = true;
    }
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Formula', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Formula', 'Inserir');
    if (this.$route.query?.idFormulaMensurando) this.buscarDadosFormulaFilha();
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    voltar: function () {
      this.$router.back();
    },
    buscarDadosFormulaFilha() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      FormulaService.buscar(this.$route.query?.idFormulaMensurando)
        .then((res) => {
          const dadosFormulaPai = {
            formulaPai: { id: res.data.id, nome: res.data.nome },
            unidadeMedida: res.data.unidadeMedida,
            variaveis: res.data.variaveis,
          };
          this.form = new FormulaModel(dadosFormulaPai);
          this.tabela.dados = [...this.form.variaveis].map((el) => {
            return new FormulaTabelaModel(el);
          });
          this.tabelaValida = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.formula.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      FormulaService.buscar(this.id)
        .then((res) => {
          this.form = new FormulaModel(res.data);
          this.tabela.dados = [...this.form.variaveis].map((el) => {
            return new FormulaTabelaModel(el);
          });
          this.tabelaValida = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.formula.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarFormulaPai(e) {
      if (!e) return;

      this.$store.dispatch('Layout/iniciarCarregamento');
      FormulaService.buscar(e.value)
        .then((res) => {
          const response = new FormulaModel(res.data);
          this.tabela.dados = [...response.variaveis].map((el) => {
            return new FormulaTabelaModel(el);
          });
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.formula.erros.id_invalido'));
          this.$store.dispatch('Layout/terminarCarregamento');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar(sequencia) {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar(sequencia);
        });
      } else this.salvar(sequencia);
    },
    salvar(sequencia) {
      this.$refs['formula'].validar();
      if (!this.$refs.form.validate() || !this.$refs.formula.ehValido()) return;

      this.$store.dispatch('Layout/iniciarCarregamento');
      FormulaService.salvar(this.form.request)
        .then((res) => {
          this.toastSucesso(this.$t(`modulos.formula.cadastro_sucesso`));
          if (!this.form.id && this.form.mensurando) {
            this.$router.push({
              name: 'formula-editar',
              params: { id: res.data },
            });
            return;
          } else if (!this.form.id && !this.form.mensurando) {
            this.$router.push({ name: 'formula' });
            return;
          }
          if (sequencia == 'sair') {
            this.$router.push({ name: 'formula' });
            return;
          }
          if (sequencia == 'continuar') {
            this.$refs['formula'].buscarVariaveis();
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'formula' });
      });
    },
    formulaMensurandoObrigatorio() {
      return !this.form.formulaMensurando;
    },
    abrirModal(validacao) {
      this.$refs['modal-validacao-formula'].abrirModal(validacao);
    },
    formulaFoiValidada(e) {
      this.formulaValida = e;
    },
    tabelaFoiValidada(e) {
      this.tabelaValida = e;
    },
    setErrors() {
      this.error.formulaMensurando.status = true;
      this.error.formulaMensurando.message = '';
    },
    resetErrors() {
      this.error.formulaMensurando.status = false;
      this.error.formulaMensurando.message = ' ';
    },
    async selecionarUnidadeMedida() {
      await FormulaService.buscar(this.form.formulaPaiId.value).then((res) => {
        this.form.unidadeMedidaId = new DropdownModel(res.data.unidadeMedida);
      });
    },
    handlerUpload(file) {
      this.form.imagem = file;
    },
    desvincularImagem() {
      this.form.imagem = null;
      this.form.nomeArquivoImagem = null;
    },
    nomeImagem(nomeImagem) {
      this.form.nomeArquivoImagem = nomeImagem ? nomeImagem : null;
    },
    regraArquivo(v) {
      if (!v) return true;
      if (v?.type == 'image/png') return true;
      return this.$t(`modulos.colaborador.validacoes.permitido_png`);
    },
    atualizarVariaveisInput(removerVariavel) {
      this.$refs['formula'].buscarVariaveis(removerVariavel);
    },
  },
};
</script>

<style lang="scss" scoped>
.adicionarItemTabela {
  color: $color-primary-grey-700;

  &:hover {
    cursor: pointer;
    background: $color-primary-grey-100;
  }
}

.footerTabela {
  background: $color-primary-grey-100;
}
</style>
