import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';
import helpers from '../../../common/utils/helpers';
export class FormulaModel {
  constructor({
    id,
    codigo,
    nome,
    descricao = '',
    mensurando = false,
    imagem = '',
    nomeArquivoImagem,
    unidadeMedida,
    simbolo,
    formula,
    formulaPai,
    variaveis = [],
    flagRequerComponentesIncerteza = false,
    flagDisponivelEmCriteriosAceitacao = false,
    flagSistema,
  }) {
    this.id = id;
    this.codigo = codigo;
    this.nome = nome;
    this.descricao = descricao;
    this.mensurando = mensurando;
    if (imagem && nomeArquivoImagem){
      let ctx = this;
      helpers
        .converterBase64ParaFile(
          imagem,
          nomeArquivoImagem,
          'image/png'
        )
        .then(function (file) {
          ctx.fileImagem = file;
        });
    }
    this.simbolo = simbolo;
    if (unidadeMedida) this.unidadeMedidaId = new DropdownModel(unidadeMedida);
    if (formulaPai) this.formulaPaiId = new DropdownModel(formulaPai);
    this.formula = formula;
    this.variaveis = variaveis;
    this.flagRequerComponentesIncerteza = flagRequerComponentesIncerteza;
    this.flagDisponivelEmCriteriosAceitacao =
      flagDisponivelEmCriteriosAceitacao;
    this.flagSistema = flagSistema;
  }

  get request() {
    if (!this.id && this.mensurando) this.formula = '';

    let retorno = _.cloneDeep(this);
    if (this.unidadeMedidaId)
      retorno.unidadeMedidaId = this.unidadeMedidaId.value;
    if (this.formulaPaiId) retorno.formulaPaiId = this.formulaPaiId.value;

    retorno.variaveis = this.variaveis.map((v) => {
      return {
        ...v,
        unidadeMedidaId: v.unidadeMedidaId?.value ?? v.unidadeMedida?.value,
        formulaCorrecaoId:
          v.formulaCorrecaoId?.value ?? v.formulaCorrecao?.value,
      };
    });

    if (!retorno.mensurando) delete retorno.variaveis;

    return retorno;
  }
}
