<template>
  <v-form ref="formTabela">
    <v-simple-table
      dense
      class="mt-3 tabela-formula-variaveis"
    >
      <thead>
        <tr>
          <th scope="col" />
          <th
            v-for="(coluna, index) in form.colunas"
            :key="index"
            scope="col"
            :width="coluna.width"
          >
            {{ coluna.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in form.dados"
          :key="index"
        >
          <td>
            <div class="d-flex align-center">
              <dropdown-padrao
                text
                color="secondary"
                class="col-2"
                :disabled="desabilitarTabela"
              >
                <template #botao>
                  <v-icon> $dotsVertical </v-icon>
                </template>
                <div>
                  <botao-padrao
                    text
                    color="secondary"
                    :disabled="desabilitarTabela"
                    @click="removerItemDaTabela(index,item)"
                  >
                    <v-icon>$mdiTrashCanOutline</v-icon>
                    {{ $t('geral.botoes.excluir_linha') }}
                  </botao-padrao>
                </div>
              </dropdown-padrao>
            </div>
          </td>
          <!-- Ações e Simbolo -->
          <td>
            <input-text
              v-model="item.simbolo"
              obrigatorio
              background-color="transparent"
              class="d-flex input-tabela"
              :disabled="desabilitarTabela"
            />
          </td>
          <!-- Unidade Medida -->
          <td>
            <div class="d-flex align-center input-tabela">
              <input-unidade-medida
                v-model="item.unidadeMedidaId"
                :disabled="desabilitarTabela"
                flat
                solo
                background-color="transparent"
                :multiplos="false"
              />
            </div>
          </td>
          <!-- Fórmula de Correção -->
          <td>
            <div class="d-flex align-center input-tabela">
              <input-formula
                v-model="item.formulaCorrecaoId"
                :formula-mensurando-id="formulaPaiId"
                :disabled="desabilitarTabela || !formulaPaiId"
                flat
                solo
                background-color="transparent"
                :multiplos="false"
              />
            </div>
          </td>
          <td>
            <input-text
              v-model="item.descricao"
              :disabled="desabilitarTabela"
              flat
              solo
              background-color="transparent"
              class="input-tabela"
            />
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <botao-padrao
              text
              color="secondary"
              :disabled="desabilitarTabela"
              @click="adicionarLinha"
            >
              <v-icon> $plus </v-icon>
              {{
                $t(
                  'modulos.procedimentos_eletronicos.tabela_dinamica.botoes.adicionar_linha'
                )
              }}
            </botao-padrao>
          </td>
        </tr>
      </tbody>
      <tbody />
    </v-simple-table>
  </v-form>
</template>
<script>
import { InputUnidadeMedida, InputFormula } from '@components/inputs';
import { FormulaTabelaModel } from '@/common/models/cadastros/FormulaTabelaModel';

export default {
  components: { InputUnidadeMedida, InputFormula },
  emits: ['tabelaFoiValidada'],
  props: {
    form: {},
    formulaPaiId: { type: String, default: '' },
    desabilitarTabela: { type: Boolean, default: false },
    mensurando: { type: Boolean, default: false },
    unidadeMedidaPai: { type: Object, default: () => {} },
  },
  data() {
    return {
      valido: false,
    };
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.formTabela) {
          this.$emit('tabelaFoiValidada', this.$refs.formTabela.validate());
        }
      },
      deep: true,
    },
  },
  methods: {
    adicionarLinha: function () {
      this.form.dados.push(
        new FormulaTabelaModel({ unidadeMedida: this.unidadeMedidaPai })
      );
    },
    removerItemDaTabela: function (index,item) {
      this.form.dados.splice(index, 1);
      this.$emit('atualizarVariaveisInput',item.simbolo)
    },
  },
};
</script>
<style lang="scss">
.tabela-formula-variaveis {
  th {
    background-color: $color-primary-grey-100;
  }
  .coluna-propriedades {
    width: 0;
  }
}
</style>
